// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../Button/Button.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import AwsSvg from "./assets/aws.svg";
import * as JsxRuntime from "react/jsx-runtime";
import AzureSvg from "./assets/azure.svg";
import GcloudSvg from "./assets/gcloud.svg";
import * as BannerGoogleMapsInfoBoxScss from "./BannerGoogleMapsInfoBox.scss";
import LogoEnzu_80x23Png from "Images/companies/logo-enzu_80x23.png";
import LogoEvocativeJpeg from "Images/companies/logo-evocative.jpeg";
import CircleSvg from "Images/companies/zenlayer/circle.svg";
import HivelocityTextPng from "Images/companies/hivelocity/hivelocity-text.png";

var css = BannerGoogleMapsInfoBoxScss;

var logoAws = AwsSvg;

var logoAzure = AzureSvg;

var logoGoogle = GcloudSvg;

function BannerGoogleMapsInfoBox(props) {
  var providerType = props.providerType;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("img", {
                              className: providerType === "hivelocity" || providerType === "evocative" ? css.providerImage : "",
                              src: providerType === "enzu" ? LogoEnzu_80x23Png : (
                                  providerType === "evocative" ? LogoEvocativeJpeg : (
                                      providerType === "aws" ? logoAws : (
                                          providerType === "gcp" ? logoGoogle : (
                                              providerType === "zenlayer" ? CircleSvg : (
                                                  providerType === "azure" ? logoAzure : HivelocityTextPng
                                                )
                                            )
                                        )
                                    )
                                )
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Clear",
                              className: css.closeButton,
                              onClick: props.onClose,
                              children: JsxRuntime.jsx(IconClose.make, {
                                    size: "MD",
                                    color: "LightGray"
                                  })
                            })
                      ],
                      className: css.topRow
                    }),
                JsxRuntime.jsx("div", {
                      children: providerType === "enzu" ? "Enzu" : (
                          providerType === "evocative" ? "Evocative" : (
                              providerType === "aws" ? "Amazon Web Services" : (
                                  providerType === "gcp" ? "Google Cloud Platform" : (
                                      providerType === "zenlayer" ? "Zenlayer" : (
                                          providerType === "azure" ? "Microsoft Azure" : "Hivelocity"
                                        )
                                    )
                                )
                            )
                        ),
                      className: css.infoBoxProviderTitle
                    }),
                JsxRuntime.jsx("div", {
                      children: props.location,
                      className: css.infoBoxProviderLocation
                    }),
                JsxRuntime.jsx("div", {
                      className: css.arrow
                    })
              ],
              className: css.infoBox
            });
}

var make = BannerGoogleMapsInfoBox;

export {
  css ,
  logoAws ,
  logoAzure ,
  logoGoogle ,
  make ,
}
/* css Not a pure module */
